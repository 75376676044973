import styled from "styled-components";
import bg from "../../assets/landing/landing-bg.png";

export const Theme = styled.div`
  .layout {
    background-image: url(${bg});
    background-size: 100%;
    background-position: top;
  }

  .section-apply .subheading {
    background: #00ce9d;
  }
  .form-wrapper {
    button[type="submit"] {
      background: #be1e2d;
      border: 1px solid #be1e2d;
    }

    .button-secondary {
      border: 1px solid #be1e2d;
      color: #be1e2d;
    }
  }

  .section-dedication {
    @media screen and (max-width: 550px) {
      .main-img {
        margin-top: 30px;
      }
      .background {
        &:before {
          background: #1a98ff;
        }
      }
    }
  }

  .section-about {
    .logo-wrapper {
      color: #be1e2d;
    }
  }

  .details-button {
    background: #be1e2d;
  }
`;
