import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../../../../atoms/Buttons/Button";
import { initForm, renderFields } from "./config";
import { validateForm } from "./validate";
import Header from "../../../Components/FormHeader";
import Container from "../../styles";
import { parseFormToRequest } from "../../../../../../utils/parseForm";
import Loader from "../../../../../molecules/Loaders/LoaderWrapper";
import {
  createNewUserApplication,
  sendLeadRemarketing,
  updateUserInfo,
  verifyLeadApi,
} from "../../../../../../api/application";
import { login } from "../../../../../../api/authorization";
import { useAppContextData } from "../../../../../../contexts/global";
import { useUserData } from "../../../../../../contexts/user";
import { LEAD_TYPE } from "../../../../../../api/types";

const Form = styled.form`
  .textField {
    margin-bottom: 12px;
    width: 100%;
  }

  & .confirm-btn {
    margin-top: 24px;
  }

  & button[type="submit"] {
    margin-top: 24px;
  }

  @media screen and (max-width: 500px) {
    .textField:first-child {
      margin-top: 12px;
    }
  }
`;

const FormComponent = ({
  moveToNextStep,
  editing,
  isActive,
  goToStep,
}: {
  moveToNextStep: any;
  editing: boolean;
  isActive: boolean;
  goToStep: any;
}) => {
  const { data, saveData } = useAppContextData(); // used to transfer form data from landing page apply form
  const [form, setForm] = useState(initForm(data));
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const {
    fetchUser,
    clearLead,
    leadId,
    user,
    triggerIdentityVerification,
    setUser,
  } = useUserData();
  const { leadType } = user?.data?.leadData || {};
  // temp logic since currently user is not created when it's web lead
  const requirePassword = leadType === LEAD_TYPE.WEB_LEAD || !user?.data;

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem("userToken");
      const screenId = localStorage.getItem("screenTrackingId");
      if (leadId && !token && !screenId) {
        setLoading(true);
        const verifyResponse = await verifyLeadApi({ leadId });
        // check if lead type implies creating application right now (web leads don't)
        if (verifyResponse.status !== 204) {
          const { userToken, screenTrackingId } = verifyResponse.data as any;
          localStorage.setItem("userToken", userToken);
          localStorage.setItem("screenTrackingId", screenTrackingId);
          clearLead();
          fetchUser();
        }
        setLoading(false);
      }
      window.analytics?.page("Personal Information");
    })();
  }, [leadId]);

  useEffect(() => {
    if (user?.data?.user) {
      let values = initForm(user.data.user, requirePassword);

      if (leadType === LEAD_TYPE.WEB_LEAD) {
        values = {
          ...values,
          ssn_number: { value: "", message: "", lockEditing: false },
        };
      } else if (leadType !== LEAD_TYPE.QUALIFIED) {
        // excluding this type on purpose
        values.ssn_number.lockEditing = true;
      }

      setForm(values);
    }
  }, [user?.data?.user]);

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    const [isValid, validatedForm] = validateForm(form, editing);

    if (isValid) {
      setLoading(true);
      const payload = parseFormToRequest(validatedForm) as any;
      console.log("::::::::::CallingRemarketingIndex", leadType);
      if (leadType === LEAD_TYPE.WEB_LEAD) {
        const response: any = await sendLeadRemarketing({
          email: payload.email,
          ssnNumber: payload.ssn_number,
          phone: payload.phoneNumber,
          requestedLoanAmount: payload.requestedLoan,
          originationUrl: window.location.href,
        } as any);
        if (
          leadType &&
          response?.data?.leadType &&
          response?.data?.leadType !== leadType
        ) {
          localStorage.clear();
          window.location.href = response.data.link;
          return;
        }
      }

      if (!editing) {
        const parsedPayload = {
          // THIS ID IS TAKEN FROM THE CDN THAT IS INJECTED IN THE SCRIPT - USED TO CREATE THE SESSION ID
          trueValidateSessionId: localStorage.getItem("trueValidateSessionId"),
          firstName: payload.firstName,
          lastName: payload.lastName,
          phone: payload.phoneNumber,
          requestedLoan: parseFloat(payload.requestedLoan),
          dob: payload.dateOfBirth,
          ssn: payload.ssn_number,
          email: payload.email,
          password: payload.password,
          street: payload.street,
          city: payload.city,
          zipCode: payload.zipCode,
          state: payload.state,
        } as any;

        const loginPayload = {
          email: payload.email,
          password: payload.password,
        };

        const result: any = await createNewUserApplication(parsedPayload);

        if (
          result?.data?.payplanExist &&
          result?.data?.payplanExist === true &&
          result?.data?.link
        ) {
          window.location.href = result?.data?.link;
          return;
        }

        if (result?.data?.data?.user) {
          window.analytics?.identify(result?.data?.data?.user?.id, {
            email: payload.email,
            firstName: payload.firstName,
            lastName: payload.lastName,
            leadId,
            leadType,
          });
        }

        const error =
          result?.error?.error?.message || result?.error?.message || "";

        if (result?.error && result?.error?.error === "expiredscreendata") {
          toast.error("Your previous session has expired. Please start over.");
          localStorage.clear();
          history.push("/");
          return;
        }

        if (error === "Email is already in use") {
          setForm(
            (prevState: any) =>
              ({
                ...prevState,
                email: {
                  ...prevState.email,
                  message: result.error.error.message,
                },
              }) as any,
          );
          setLoading(false);
          return;
        }
        // IF THE USER IS REGISTERED WITH IDENTITY VALIDATION RISK - CALL KBA/OTP VALIDATION POPUP
        if (
          result &&
          result.error &&
          result.error.error.message &&
          result.error.error.status === "pending" &&
          result.error.context.screentrackingId
        ) {
          const loginResult = await login(loginPayload);
          // LOGIN ERROR
          if (loginResult.error) {
            // toast.error("login error");
          } else {
            const userData = await fetchUser();
            triggerIdentityVerification(
              result?.error?.context?.screentrackingId ||
                userData?.screenTrackingId,
            );
          }
          setLoading(false);
          return;
        }
        if (
          result &&
          result.error &&
          result.error.error.message &&
          result.error.error.status === "failed" &&
          result.error.context.screentrackingId
        ) {
          saveData(user);
          setUser({});
          localStorage.clear();
          history.push("application/thankyou");
          return;
        }
        if (
          result &&
          result.error &&
          result.error.error.message &&
          result.error.error.status === "failed" &&
          !result.error.context.screentrackingId
        ) {
          const isDuplicate = String(result.error.error.message)
            .toLowerCase()
            .includes("duplicate");
          if (isDuplicate) {
            saveData(user);
            localStorage.clear();
            history.push("application/thankyou");
            return;
          }
        }
        if (
          result &&
          result.error &&
          result.error.error.message &&
          result.error.error.status === "retry" &&
          result.error.context.screentrackingId
        ) {
          setForm(
            (prevState: any) =>
              ({
                ...prevState,
                ssn_number: {
                  ...prevState.ssn_number,
                  message: result.error.error.message,
                },
              }) as any,
          );
          setLoading(false);
          return;
        }
        if (
          result &&
          result.error &&
          result.error.error.message &&
          result.error.error.status !== "pending" &&
          result.error.context.screentrackingId
        ) {
          // SERVER-SIDE FORM VALIDATION ERROR
          // toast.error(result.error.error.message);
          const updateUserRes = await updateUserInfo(parsedPayload);
          if (updateUserRes && updateUserRes.error) {
            setLoading(false);
            return;
          }
        }

        if (result?.data?.data?.userToken) {
          // optimistic update, fetch should happen below anyway
          setUser({ data: { user: result.data.data.user } });
          localStorage.setItem("userToken", result.data.data.userToken);
          localStorage.setItem(
            "screenTrackingId",
            result.data.data.screentracking.id,
          );
        }

        if (localStorage.getItem("isRemarketing") === "true") {
          clearLead();
          fetchUser();
          setLoading(false);
          moveToNextStep();
          return;
        } else if (leadId) {
          setLoading(false);
          clearLead();
          fetchUser();
          moveToNextStep();
          return;
        }

        const loginResult = await login(loginPayload);
        // LOGIN ERROR
        if (loginResult.error) {
          // toast.error("login error");
        }
        const userData = await fetchUser();

        // ON FORM DATA APPROVED AND NO ADDITIONAL CHECKES REQUIRED - MOVE TO NEXT STEP
        if (
          !result.error &&
          !loginResult.error &&
          userData &&
          userData.lastlevel !== 1
        ) {
          moveToNextStep();
        }
      } else {
        // UPDATE THE PERSONAL INFO FORM
        const result = await updateUserInfo(payload);
        if (result && !result.error) {
          toast.success("Personal information successfully updated!");
          moveToNextStep();
          fetchUser();
        } else {
          // toast.error("something went wrong");
        }
      }
      setLoading(false);
    } else {
      // SET FORM VALIDATION ERRORS
      setForm(validatedForm);
    }
  };

  const onChangeHandler = (e: any) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: { value: e.target.value, message: "" },
    }));
  };

  const title = "Personal Information";
  const webLeadNote =
    "Tell us more about yourself. This is needed for accurate and fast application approval.";
  const reborrowerNote =
    "Please verify the personal information below to receive your rapid cash deposit.";
  const isExistingLead = [
    LEAD_TYPE.RE_BORROWER,
    LEAD_TYPE.UNCONVERTED_LEAD,
  ].includes(leadType);
  const note = isExistingLead ? reborrowerNote : webLeadNote;

  if (!isActive) {
    return <></>;
  }

  return (
    <Container>
      <Loader loading={loading}>
        <Form onSubmit={onSubmitHandler}>
          <Header title={title} note={note} />
          {renderFields(form, editing, requirePassword).map(
            ({ component: Component, lockEditing, ...field }) => {
              return (
                <Component
                  disabled={lockEditing}
                  key={field.name}
                  {...field}
                  onChange={onChangeHandler}
                />
              );
            },
          )}

          <Button type="submit" variant="primary">
            Confirm
          </Button>
        </Form>
      </Loader>
    </Container>
  );
};

export default FormComponent;
